import axios from 'axios';
import { store } from '../redux/store.js';

import { setTokenDead } from '../redux/actions/authActions';

const LOCAL_SERVER = false;

export const apiUrl = () => {
  if (LOCAL_SERVER) {
    return 'http://127.0.0.1:10002';
  }

  if (window.location.hostname === 'homolog.nexatlas.com') {
    return 'https://homolog-api.nexatlas.com';
  }

  return 'https://api.nexatlas.com';
};

export const aerodataUrl = () => {
  if (LOCAL_SERVER) {
    return 'http://127.0.0.1:9004';
  }

  return 'https://aerodata.nexatlas.com';
};

export const loginWebClient = axios.create({
  baseURL: apiUrl(),
  responseType: 'json',
  withCredentials: true,
});

export const apiWebClient = axios.create({
  baseURL: apiUrl(),
  responseType: 'json',
  withCredentials: true,
});

apiWebClient.interceptors.request.use(
  config => {
    config.headers.Authorization = `Token ${getAuthorization()}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

apiWebClient.interceptors.response.use(
  response => {
    if (response.data && response.data.message === 'authorizationTokenIsDead') {
      // Unauthorized
      store.dispatch(setTokenDead());
    }

    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      // Unauthorized
      store.dispatch(setTokenDead());
    }

    return Promise.reject(error);
  },
);

export const aerodataWebClient = axios.create({
  baseURL: aerodataUrl(),
  responseType: 'json',
  withCredentials: true,
});

export function apiGetBlob(url, data, callback) {
  apiWebClient
    .post(url, data, {
      responseType: 'blob',
      timeout: 30000,
    })
    .then(blob => {
      const file = new Blob([blob.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      callback(fileURL);
    });
}

function getAuthorization() {
  return store.getState().auth.token;
}
